<template>
  <div v-if="filtros && filtros.length > 0">

    <v-spacer></v-spacer>
    <v-chip
      v-for="(filter, key) in filtros"
      class="ma-1"
      label
      outlined
      color="primary"
      :key="key"
    >
      {{
        `${filter.label}: ${
          Array.isArray(filter.model)
            ? getTextWhenFilterIsArray(filter)
            : filter.model
        }`
      }}
    </v-chip>
  </div>
</template>
<script>
export default {
    name:'FiltersSelected',
    props:['filters'],
    mounted(){
      this.filtros = this.filters.filter( x => x.model.length > 0);
    },
    data(){
      return {
        filtros: []
      };
    },
    methods:{
      getTextWhenFilterIsArray(currentFilter){
        if(currentFilter.model.length > 0 && currentFilter.model.length <= 3){
          return currentFilter.model.map((m) => { return m.value ? m.value : m});
        }
        if(currentFilter.model.length > 3){
            let totalFiltros = currentFilter.model.length - 3;
            let firstThree = currentFilter.model.slice(0, 3).map((f) => { return f.value ? f.value : f; })
            return `${firstThree} y ${totalFiltros} más`;
        }
        
      }
    }
}
</script>
